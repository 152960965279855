.group-info {
  padding: 15px;

  .group-info-account {
    h3 {
      margin-top: 0;
    }
  }

  .group-info-folder {
    h3 {
      margin-top: 0;
    }

    .folder-name {
      width: 400px;
    }
  }

  .group-info-course {
    h3 {
      margin-top: 0;
    }
  }
}

/** Course form */

.course-form {

  a {
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  input {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 12px;
  }

  .course-name {
    width: 390px;
  }

  .input-append {
    margin-bottom: 0;
  }

  .course-dropdown {
    margin-right: 3px;


    ul.dropdown-menu li.expired a {
      color: @color-med-grey;
    }
  }
}
