option {
  padding: 2px;
}

optgroup {
  padding: 0 4px 4px;
}

.margin10 {
  margin: 10px;
}
