.side-panel.activity-report-panel {

	.side-panel-container {
		width: inherit;

		.side-panel-header {
			max-height: 10vh;
		}

		.side-panel-body {
			padding: 5px;
		}

		.side-panel-footer {
			max-height: 15vh;
			gap: 16px;
		}
	}

	.modal-dialog {
		width: 1000px;
	}

	activity-report {
		flex: 1;
		width: inherit;
	}

	@media (max-width: 1000px) {
		.modal-dialog, activity-report {
			width: 100%;
		}
	}
}
