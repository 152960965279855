@import '../auth-variables.less';

student-signup-view {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: inherit;

	.button-container {
		width: 100%;
		text-align: right;
		margin-top: 42px;
	}
}
