marker-set-panel {
	display: block;

	panel-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		margin-right: 10px;
	}

	.marker-set-name {
		display: block;
		margin-bottom: 5px;
	}
}
