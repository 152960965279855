ui-view-inner {
	flex: 1;
	display: flex;
	width: inherit;
	min-height: 0;

	main {
		display: flex;
		flex: 1;
		width: inherit;
	}
}
