@import 'variables';

.spacer () {
	&:before {
		content: '';
		margin: auto 8px;
		font-weight: bold;
	}
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant-no-border(@color; @background) {
  color: @color;
  background-color: @background;
  border: none;
  text-shadow: none;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 8%);
  }
  &:active,
  &.active,
  .open .dropdown-toggle& {
    background-image: none;
	color: @color;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 8%);
    border-color: darken(@border, 12%);
  }
  &:active,
  &.active,
  .open .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
      border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

.button-transparent-variant(@color; @background; @border) {
  .button-variant(@color; @background; @border);
  background-color: transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: darken(@border, 8%);
    background-color: @background;
  }

  &.active,
  &:active {
    box-shadow: none;
  }
}

.btn-hover-bg() {

  .btn {
    background-color: transparent;
  }

  .btn:hover,
  .btn:active {
    background-color: @color-xlight-grey;
    box-shadow: inset 1px 1px 1px 1px @color-light-grey2;
  }
}

.vertical-align() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.focus-form-element() {
  border-color: @color-primary-focus;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

// Browser Compatibility
// -------------------------
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}
