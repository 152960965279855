@import '~go-styles/src/_/variables';

.download-csv-report {
	margin-right: 20px;
}

activity-report {
  @row-height: 20px;
	height: 100%;
	display: flex;

  .ui-grid {
    width: 100%;
    height: 100%;

    .ui-grid-invisible {
      display: none;
    }

    .ui-grid-column-menu-button {
      display: none;
    }

    .ui-grid-expandable-buttons-cell {
      cursor: pointer;
    }

    .ui-grid-header-cell {
      text-align: center;
      background-color: @color-off-white-secondary;
      width: 100%;

      &.num-videos-reviewed,
      &.total-comments,
      &.avg-comment-length {
        background-color: #fae8b8;
      }

      &.num-videos-presented,
      &.marker-counts,
      &.total-score {
        background-color: #c7e4af;
      }

      &.full-name {
        text-align: left;
      }

      &.avg-comment-length {

      }

      &.marker-counts {
        text-align: left;
      }

      .ui-grid-cell-contents {
        height: 45px;
        white-space: normal;
        -ms-text-overflow: clip;
        -o-text-overflow: clip;
        text-overflow: clip;
        overflow: visible;
      }
    }

    .ui-grid-cell {
      text-align: center;

      &.full-name {
        text-align: left;
      }

      &.marker-counts {
        padding-top: 0;
      }
    }

    .ui-grid-pinned-container.ui-grid-pinned-container-left .ui-grid-header-cell:last-child {
      border-right: none;
    }

    .ui-grid-pinned-container.ui-grid-pinned-container-left .ui-grid-cell:last-child {
      border-right-color: @color-light-grey;
    }

    .ui-grid-row:last-child {
      border-bottom: 1px solid @color-light-grey;
    }

    .ui-grid-render-container-body {
      > .ui-grid-viewport {
        overflow: auto!important;
      }
    }

    .expandableRow {

      .ui-grid {

      }

      .ui-grid-header-cell {
        background-color: @color-white !important;

        .ui-grid-cell-contents {
          height: 30px;
          white-space: normal;
          -ms-text-overflow: clip;
          -o-text-overflow: clip;
          text-overflow: clip;
          overflow: visible;
        }
      }
    }
  }

  zero-state {
    align-self: center;
    margin: 0 auto;
  }
}

.course-report {
	padding: 0 20px;

	.course-report-header {
		display: grid;
		grid-template-columns: 230px repeat(5, 1fr) 181px;
		border-bottom: 1px solid @color-light-grey2;

		.header-cell {
			padding: 10px 5px;
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&.align-left {
				text-align: left;
			}
		}
	}

	.user-list {
		.user-report {
			.user-stats-header {
				display: grid;
				grid-template-columns: 230px repeat(5, 1fr) 181px;
				border-bottom: 1px solid @color-light-grey2;
			}

			.stats-markers {
				padding-top: 5px;
			}

			.expand-name {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				i.flipped::before {
					transform: rotate(180deg);
				}
			}

			.toggle-report-button {
				border: none;
				background: none;
				font-size: 24px;
			}

			.body-cell {
				padding: 10px 5px;
				margin: 0;
				font-size: 14px;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;

				&.align-left {
					text-align: left;
				}
			}

			.no-sessions-message {
				text-align: center;
				font-size: 12px;
				margin: 20px;

			}

			.stats-table {
				width: 100%;
				border-collapse: collapse;
				background-color: #efefef;

				th, td {
					padding: 5px 10px;
					border: 1px solid @color-light-grey2;
				}
			}
		}
	}
}
