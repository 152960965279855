@import '~go-styles/src/_/variables';

.side-panel.activity-editor-panel {

	@media (min-width: 768px) {
		.modal-dialog {
			width: 375px;
		}
	}

	.side-panel-container {
		max-width: 100%;
		height: 100%;

		.slim-header {
			height: 45px;
			flex: 0 0 46px;
		}

		@media (max-width: 767px) {
			display: block;

			.side-panel-header {
				max-height: 12vh;
			}

			.side-panel-footer {
				max-height: 22vh;
			}

			.side-panel-body {
				background-color: @color-white;
			}
		}
	}
}
