/* Import global style variables */
@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/mixins';

/** Common */
@import 'common/general';
@import 'common/tables';
@import 'common/structure';
@import 'common/role-distinctions';
@import 'common/label-input';
@import 'common/page';
@import 'common/modals';
@import 'common/animations';
@import 'datepicker';
@import 'forms';
@import 'overlay';
@import 'course-editor';
@import 'group-info';
@import 'other';
@import 'overrides';
@import 'banners';

html {
	height: 100%;
	// allow font-size to be set by browser (for rem-reliant sizing)
	font-size: initial;
	cursor: auto;
}

go-bootstrap, go-bootstrap > section {
  height: 100%;
}

button, select, html, textarea, input {
	font-family: proxima-nova, 'Open Sans', sans-serif;
}


@keyframes fade {
	from {
		opacity: 0;
	}
}

.page-body.align {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.page-body.align .logo {
	width: 75px;
	animation: fade 1.1s infinite alternate;
}

body .view-container {
	height: 100%;
	width: 100%;
	display: block;
	flex: 1;
	min-height: 0;

	> .loading {
		display: flex;
		align-items: center;
		justify-content: center;
		height: inherit;

		> img {
			align-self: center;
			width: 75px;
			animation: fade 1.1s infinite alternate;
		}
	}
}

body {
	font-family: proxima-nova, 'Open Sans', sans-serif !important;
	display: flex;
	flex-direction: column;
	height: 100%;

	// needed to avoid overscroll on mobile
	&:has(ngx-session-view) {
		@media (max-width: @screen-collapse-min) {
			overflow: hidden;
		}
	}
}

.main {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;

	> [ui-view] {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-height: 0;
		width: 100%;

		> * {
			flex: 1;

		}
	}
}

.vertical-nav {
	license-seats-management {
		width: 100%;
	}
}

caption.table-caption {
	font-weight: bold;
	color: @color-dark;
}

// Only top level zero states
zero-state.full-screen {
	flex: 1;
	width: 100%;

	zero-state-body {
		margin-top: 30px;
		width: 450px;
		max-width: 98%;
	}
}

.no-course-zero-state {
	.heading {
		order: 1;
	}
	.graphic {
		order: 2;
		margin-top: 25px;
	}
	.subtext {
		order: 3;
		font-size: 20px;
	}
	.body {
		order: 4;
	}
}

// Zen-desk widget started covering some of our buttons on screen
// even when it wasn't active.  I hate 3rd party widgets
.zEWidget-webWidget {
	right: 9999px !important;

	&.zEWidget-webWidget--active {
		right: 0 !important;
	}
}

.preformatted {
	white-space: pre-wrap;
}
