@import '~go-styles/src/_/variables';

instructions-panel {
	display: block;

	panel-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		margin-right: 10px;
	}

	.instructions-text {
		margin: 5px 0;
		max-height: 20px;
		overflow: hidden;

		p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.instructions-media {
		display: flex;

		.instructions-media-thumbnail {
			min-width: 45px;
			max-width: 45px;
		}

		.instructions-media-title {
			padding-left: 10px;
			align-self: center;
		}
	}
}
