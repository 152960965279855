resources-panel {
	panel.hide-paneling {
		background-color: transparent;
		border: none;

		panel-heading {
			display: none;
		}

		panel-heading,
		panel-body {
			padding: 0;
			background-color: transparent;
			border: none;
		}
	}

	activity-attachments {
		flex: 1;
	}
}
