// Custom styles for banner appearances on dashboard only
@import '~go-styles/src/_/variables';

.go-banner-active {

	// This helps the banners look centered against the extra 8px border curve on top of dashboard
	go-banner:last-child .content {
		padding-bottom: @go-banner-padding-top;
		padding-top: @go-banner-padding-top;
	}

	&.go-banner-active-notice {

		dashboard-root-view {
			background-color: @go-banner-notice-color;
		}
	}

	&.go-banner-active-informational {

		dashboard-root-view {
			background-color: @go-banner-informational-color;
		}
	}

	&.go-banner-active-warning {

		dashboard-root-view {
			background-color: @go-banner-warning-color;
		}
	}
}
