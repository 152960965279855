@import '~go-styles/src/_/variables';
@import './auth-variables.less';

ui-view {
	display: flex;
	flex-direction: column;
	height: inherit;
	background-color: @auth-page-background;

	@media (max-width: @auth-page-breakpoint) {
		background: @color-white;
	}
}

.auth-page-container {
	display: flex;
	min-height: 0;
	overflow: auto;
	width: 100%;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 120px 30px 60px 30px;
	height: inherit;
	background: url("https://staticassets.goreact.com/auth-bg.svg");
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width: @auth-page-breakpoint) {
		padding: 0;
		background: @color-white;
	}

	.action-section {
		margin-top: 13px;
	}

	.button-actions {
		display: flex;
		justify-content: space-between;
		margin-top: 5px;

		.responsive-action-button:last-of-type {
			margin-top: 24px;
		}
	}

	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-height: 100px;
		margin-bottom: 64px;

		@media (max-width: @auth-page-breakpoint) {
			margin-top: 35px;
			margin-bottom: 33px;
		}

		.logo {
			height: 50px;
			width: 201px;
		}
	}

	.content-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.content-space {
		background-color: @color-white;
		border-radius: 15px;
		padding: 52px 52px 30px 52px;
		width: 464px;

		@media (max-width: @auth-page-breakpoint) {
			width: 100%;
			padding: 30px;
		}
	}

	h4 {
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 33px;

		@media (max-width: @auth-page-breakpoint) {
			margin-bottom: 15px;
		}
	}

	.cancel-continue-btn-container {
		text-align: right;

		button:first-child {
			margin-right: 15px;
		}
	}

	.alert-message {
		color: @color-primary;
	}
}
