@import '../auth-variables.less';

login-view {
	height: inherit;

	.forgot-password-section {
		margin-bottom: 5px;
	}

	.email-section {
		margin-bottom: 10px;
	}

	.button-actions {
		display: flex;
		flex-direction: column;
		margin-top: 5px;

		.responsive-action-button {
			flex: 1;

			button {
				width: 100%;
			}

			p {
				margin-bottom: 14px;
			}
		}

		.primary-btn {
			margin-top: 0;
		}
	}
}
