@import '~go-styles/src/_/variables';

.modal.activity-instructions-editor-modal {
	@container-height: 300px;

	.modal-dialog {
		@media (min-width: 526px) {
			width: 800px;

			.goreact-modal-body {
				flex-direction: row;
			}

			.goreact-modal-body.flex-column {
				flex-direction: column;
			}

			.text-instructions-container {
				margin-right: 15px;
			}
		}

		@media (max-width: 525px) {
			.text-instructions-container {
				margin-bottom: 15px;
			}
		}

		@media (max-width: 800px) {
			width: auto;
		}
	}

	.goreact-modal-body {
		display: flex;
		flex-direction: column;
		padding-bottom: 0px;

		ng-messages {
			margin-top: 15px;
		}
	}

	.text-instructions-container {
		display: flex;
    	flex-direction: column;
		flex: 1;
		min-height: @container-height;
	}

	.text-instructions-input {
		flex: 1;
		resize: none;
	}

	.add-media-btn {
		min-width: 0;
		margin: auto;
	}

	.media-instructions-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-height: @container-height;

		media-thumbnail {
			flex: 1;
			background-color: black;
		}
	}

	.goreact-modal-footer {
		justify-content: space-between;
		padding-top: 15px;
		@media (max-width: 480px) {
			* + * {
				margin-top: 0;
			}

			.left-group-buttons {
				min-width: 100%;
				button {
					width: 100%;
				}
			}
			align-items: flex-end;
		}

		.right-group-buttons {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
			@media (max-width: 480px) {
				flex-direction: row-reverse;
			}
		}
	}

	.instructions-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-height: @container-height;

		.media-instructions-container {
			min-height: 0;
			margin-top: 30px;
			panel-heading {
				display: flex;
				justify-content: space-between;

				.actions {
					margin-right: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					i {
						margin-left: 4px;
						margin-right: 4px;
						cursor: pointer;
					}

					.ficon-app-edit {
						font-size: 16px;
					}

					.ficon-times {
						font-size: 12px;
						color: @color-primary;
					}
				}

				label {
					margin-left: 15px;
					@media (max-width: 480px) {
						margin-left: 0;
					}
				}
			}

			panel-body {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;

				media-thumbnail {
					display: flex;
					overflow: hidden;
					margin-left: 15px;
					width: 50px;
					max-width: 50px;
					background-color: black;
					@media (max-width: 480px) {
						margin-left: 0;
					}
				}

				.title {
					margin: 0 8px;
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
				}
			}
		}

		.goreact-label {
			font-weight: 600;
		}
	}

	.modal-content {
		@media (max-width: 480px) {
			-webkit-box-shadow:0 3px 9px rgb(0, 0, 0, 50%);
			box-shadow:0 3px 9px rgb(0, 0, 0, 50%);
		}
	}

	.mce-last.mce-btn-group {
		border-left: none;
	}

	.label-container {
		display: flex;
		height: 35px;
		justify-content: space-between;
		margin-bottom: 5px;
		label {
			margin-bottom: 0;
		}

		span {
			cursor: pointer;
			i {
				font-size: 25px;
			}
		}
	}
}
