/** HEADER, BODY, FOOTER */

.header {
	color: @color-dark;
	height: 50px;

	.heading {
		padding-left: 10px;
	}

	.heading.test {
		background-color: @color-dark2;
		border-radius: 10px 10px 0 0;
	}

	h3 {
		margin: 0;
	}

	> .title {
		display: inline-block;
		font-size: 14px;
		margin-left: 10px;
	}
}

.inline-header {
	height: 40px;

	> .title {
		margin-top: 10px;
		margin-left: 10px;
		font-size: 14px;
	}
}

.footer {
	height: 40px;

	> .btn,
	> .btn-group {
		display: inline-block;
		vertical-align: middle;
	}
}

/** PANE */

.pane {
	min-height: 180px;
	z-index: 7;
	display: flex;
	height: 100%;
	width: inherit;
	flex-direction: column;
	flex: 1;

	.pane-title {
		color: @color-light-grey2;
		font-weight: 300;
		margin-left: 16px;
		float: left;
		.vertical-align();
	}

	> .header {
		background-color: @color-white;
		overflow: hidden;
		height: 44px;
		font-size: 15px;
		color: @color-white;

		> *:first-child {
			margin-left: 10px;
			margin-right: 10px;
			.vertical-align();
		}

		> *:last-child {
			margin-right: 10px;
			.vertical-align();
		}

		.nav-pills {
			float: left;
			margin-top: 1px;
			.vertical-align();

			li {
				font-size: 15px;
			}
		}
	}

	> .body {
		top: 44px;
		background-color: @color-white;
		flex: 1;
	}

	.body {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
	}

	> .footer {
		border-top: 1px solid @color-light-grey2;
		background-color: @color-xlight-grey;

		.button-group {
			margin-right: 5px;
		}
	}
}
