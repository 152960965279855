@import '~go-styles/src/_/variables';

/** Overlay */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: @color-white;

  &.overlay-xlight-grey {
    background-color: @color-off-white-secondary;
  }

  &.transparent {
    opacity: 0.60;
    filter: alpha(opacity=60);
  }

  > .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 18px;
    margin: auto;
    text-align: center;
    max-width: 750px;
    font-size: 18px;

    p {
      margin: 0;
    }
  }

  &.overlay-lg {
    > .inner {
      font-size: 24px;
      height: 24px;
      line-height: 28px;

      p {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
