source-media-panel {
	display: block;

	panel-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.source-media {
		display: flex;
		align-items: center;

		.source-media-thumbnail {
			width: 45px;
			min-width: 45px;
		}

		.source-media-title {
			margin: 0 8px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
