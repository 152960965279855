@import '~go-styles/src/_/variables';

.vertical-nav {
	display: flex;
	flex-direction: column;
	flex: 1;

	.sidebar {
		flex-basis: 20%;
	}

	main {
		flex: 1;
	}
}

dashboard-root-view {
	display: flex;
	flex-direction: column;
	min-height: 0;
	height: inherit;

	// We don't need extra margin when super bar is showing
	&.root-user {
		padding-top: 0;
	}

	.no-course-zero-state {
		zero-state-title {
			margin-bottom: 0;
		}

		zero-state-graphic > img {
			width: 100px;
		}
	}
}
