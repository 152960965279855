accessibility-skip-links {
	position: fixed;
	top: 20px;
	left: 15px;
	transform: none;
	z-index: 10;
	visibility: hidden;
	width: 320px;

	.skip-link-btn {
		position: absolute;
		top: 0;
		transform: translateX(-200%);
		transition: transform .2s ease-out, opacity 0ms linear .2s;
		opacity: .01;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
		visibility: visible!important;

		&:focus {
			opacity: 1;
			transition: transform .2s ease-out;
			transform: translateX(0);
		}
	}
}
