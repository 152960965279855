course-editor {
	margin-bottom: 15px;
}

.create-course-modal {
	@media (min-width: 550px) {
		.modal-dialog {
			width: 400px;
			margin: 30px auto;
		}
	}
}
