@import '~go-styles/src/_/variables';
// Added to display new angular tooltip on video-sharing app
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body.zendesk-zoomed-in-override {
	iframe#webWidget {
		max-height: calc(100vh + 38px) !important;
		height: calc(100vh + 38px) !important;
	}
}

header-view {
	height: 45px;
	line-height: normal;
	display: flex;
	padding: 0 20px;
	background-color: @color-grey-highlight;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;

	.dropdown-menu {
		z-index: 1001;
	}

	.with-license-menu-item .dropdown-menu {
		min-width: 180px;
	}

	.header-left {
		display: flex;
		align-items: center;
	}

	.header-inner {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

	.toolbar {
		display: flex;
		align-items: center;

		button {
			padding: 0 8px;
		}
	}

	.logo {
		margin: 0 10px 0 0;
		img {
			height: 26px;
		}

		@media (max-width: @screen-xxs-min) {
			display: none;
		}
	}

	.back-assignment {
		display: flex;
		text-transform: uppercase;

		@media (max-width: @screen-xxs-min) {
			padding-left: 0;
		}
	}

	.back-assignment, .zendesk-btn, .suggestion-panel-btn, .library-btn, .user-widget-btn, .feature-flag-btn  {
		i {
			font-size: 20px;
		}
	}

	.feature-flag-btn {
		margin-right: 5px;
	}

	.user-widget-btn {
		display: flex;
		align-items: center;
		padding: 0 0 0 7px;
	}

	.profile-icon {
		display: none;
	}

	@media (max-width: @screen-xs-min) {
		.profile-name {
			display: none;
		}

		.profile-icon {
			display: initial;
		}
	}

	@media (max-width: @screen-xs-min) {
		.hidden-xs-list-item {
			display: none;
		}
	}
}
