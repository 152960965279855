@import '~go-styles/src/_/variables';

.side-panel.group-settings-side-panel {
	// Have to manually set this or else the ngx confirm dialog shows up behind this
	~ .cdk-overlay-container{
		z-index: 1060 !important;
	}

	.modal-dialog {
		width: 860px;
		max-width: 100vw;
		overflow: auto;

		.modal-title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		@media(max-width: @screen-lg-min) {
			width: 660px;
		}

		@media(max-width: @screen-sm-min) {
			width: 100%;
		}
	}

	.form-horizontal {
		margin-top: 20px;
	}

	.group-info-account {
		.form-group {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.account-users {
	height: 100%;
}

.close-button {
	margin-left: auto;
	margin-right: 0.5rem;
}

.user-action-button .ficon-app-dots-vertical {
	font-size: 1.25rem;
}

.user-action-dropdown {
	display: block;
	text-align: center;
}

.users-panel {
	.body {
		overflow: visible;
	}

	.invite-users-btn {
		margin: 10px;
	}

	.removed-users {
		margin: 20px 0;
	}

	.num-users-badge {
		+ .tooltip {
			min-width: 120px;
		}
	}

	table {
		table-layout: fixed;
		width: auto;

		tr {
			cursor: pointer;
		}
	}
}

.tabset {
	display: flex;
	flex-flow: column;
	// 100% - header - footer
	height: calc(100% - 121px);
	padding: 0px 15px;
	background-color: @color-white;

	.tab-content {
		flex-grow: 1;
		overflow: auto;
	}

	.nav {
		display: flex;
		flex-flow: row;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
}

.invited-users-table{
	.email {
		max-width: 265px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

table caption {
	color: @color-darker-grey-font;
	font-weight: bold;
}

tbody th {
	font-weight: normal;
}
