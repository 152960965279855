@import '../auth-variables.less';

group-confirm {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: inherit;

	.loading-group-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.ficon-spinner {
			margin-bottom: 20px;
		}
	}

	.text-container {
		padding: 10px 0;

		@media (max-width: @auth-page-breakpoint) {
			padding: 5px 0;
		}

		span {
			display: block;
		}

		.creator-name {
			font-weight: 600;
		}

		.group-name {
			font-weight: 600;
		}

		.role {
			font-weight: 600;
			text-transform: capitalize;
		}
	}

	.confirmation-message {
		.footer-info {
			@media (max-width: @auth-page-breakpoint) {
				span {
					font-size: 13px;
				}
			}
		}
	}

	.course-invite {
		.button-container {
			margin-top: 20px;
		}
	}
}
