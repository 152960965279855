/** FORMS */

form.modal {
	margin-bottom: 0;
}

form .alert {
	margin-bottom: 0;
	margin-top: 5px;
	padding: 6px 25px 6px 10px;
}
