@import '~go-styles/src/_/variables';

/**  ROLE COLORS */

.role-owner {
	background-color: @color-dark;
}

.role-admin {
	background-color: @color-primary;
}

.role-instructor {
	background-color: var(--color-secondary);
}

.role-reviewer {
	background-color: @color-yellow;
	color: @color-xdark;
}

.role-presenter {
	background-color: @color-emperor;
}
