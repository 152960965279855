rubric-panel {
	display: block;

	panel-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		margin-right: 10px;
	}

	panel-body {
		.reviewers-rubrics-access {
			.checkbox {
				margin-bottom: 0px;
			}
		}
	}
}
