@import '~go-styles/src/_/variables';

#org-account-list {
	.btn-none:hover,
	.btn-none:active,
	.btn-none.active,
	.btn-none.disabled,
	.btn-none[disabled] {
		color: @color-dark;
		background: none;
	}

	> .table {
		border: 1px solid @color-xlight-grey;

		th.action-header {
			min-width: 100px;
		}
	}

	.org-search-container {
		margin-bottom: 15px;
	}
}
