@import '~go-styles/src/_/variables';

course-template {
	uib-dropdown.course-dropdown {
		width: 100%;

		.dropdown-button {
			width: calc(100% - 26px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: block;
			text-align: left;
		}

		.dropdown-menu {
			width: 100%;
		}
	}

	.copy-assignments {
		margin-top: 10px;
	}

	course-editor {
		margin-bottom: 0px;

		.course-editor-container {
			.add-dates-form {
				margin-bottom: 0px;
			}
		}
	}
}

.publish-course {
	margin-top: 20px;
}

.divider-bottom {
	border-bottom: 1px solid @color-separator;
	padding-bottom: 5px;
	margin-bottom: 5px;
}
