
.slide {
	.page-body {
		overflow: hidden;
	}
	[ng-view] {
		&.ng-enter,
		&.ng-leave {
			position: absolute;
			transition: 0.4s ease-in all;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
}

.slide.animate-from-left {
	[ng-view] {
		&.ng-leave.ng-leave-active {
			transform: translateX(100%);
		}
		&.ng-leave {
			transform: translateX(0);
		}
		&.ng-enter.ng-enter-active {
			transform: translateX(0);
		}
		&.ng-enter {
			transform: translateX(-100%);
		}
	}
}

.slide.animate-from-right {
	[ng-view] {
		&.ng-leave.ng-leave-active {
			transform: translateX(-100%);
		}
		&.ng-leave {
			transform: translateX(0);
		}
		&.ng-enter.ng-enter-active {
			transform: translateX(0);
		}
		&.ng-enter {
			transform: translateX(100%);
		}
	}
}
