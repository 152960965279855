
/**  APP NAV STYLES  */

ul.nav button,
ul.nav li a {
	cursor: pointer;
}

.input-group {
	[class*=" ficon-"],
	[class^=ficon-] {
		font-size: 14px;
	}
}
