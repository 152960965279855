@import '~go-styles/src/_/variables';

// Have to manually set this or else the ngx confirm dialog shows up behind this
.activity-editor-panel ~ .cdk-overlay-container{
	z-index: 1060 !important;
}

activity-editor {
	display: block;

	.ficon-sparkles {
		text-decoration: unset;
	}

	.activity-name-group {
		border-bottom: 1px solid @color-xlight-grey2;

		md-input-container {
			padding-bottom: 30px;

			&.md-input-invalid {
				padding-bottom: 15px;
			}
		}
	}

	.accordion {
		padding: 25px 0px;
		border-bottom: 1px solid @color-xlight-grey2;

		.accordion-header {
			display: flex;
			flex-direction: column;

			.accordion-header-top {
				display: flex;
				justify-content: space-between;
				font-weight: bold;

				.ficon-app-chevron-down {
					font-size: 1.4em;

					&.rotated::before {
						transform: rotate(180deg);
					}
				}
			}
		}

		.accordion-content {
			max-height: 1000px;
			transition: max-height 0.3s ease-in;

			&.ng-hide {
				max-height: 0;
				transition: max-height 0.15s ease-out;
			}

			&.ng-animate {
				overflow: hidden;
			}
		}

		.checkboxes {
			.form-group {
				&:first-of-type {
					margin-top: 15px;
				}

				&:last-of-type {
					margin-bottom: 0px;
				}
			}
		}

		.activity-resources-group {
			padding: 15px 15px 0px 0px;
		}
	}

	.ae-activity-template {
		go-popover {
			display: inline-block;
			transform: translate3d(6px, 16px, 0);
			position: relative;
			z-index: 5;
			background: white;
		}
	}

	// this style is important so popovers are still clickable and dont toggle input
	.checkbox input[type="checkbox"] {
		width: 21px;
		height: 21px;
	}

	.link-button {
		margin: 0 0 4px;
	}

	.select-box {
		flex: 1;
	}

	.input-box {
		flex: 1;
		margin-left: 15px;
	}

	.goreact-label {
		display: inline-block;
	}

	.auxiliaries-section .form-group {
		margin-bottom: 4px;
	}

	.lms-warning-message-section {
		margin-top: 20px;
		strong {
			color: @color-primary-error;
		}
	}

	.checkboxes {
		.form-group {
			margin-bottom: 10px;

			> .checkbox {
				margin: 0;
			}
		}
	}

	.sharing-space-with-points {
		min-width: 194px;
	}

	.grading-options {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		margin-bottom: 15px;

		.comment-only-group {
			margin-bottom: 0px;

			.goreact-label {
				text-transform: none;
			}
		}
	}

	.test-settings-time-limit {
		ng-messages {
			display: block;
		}

		input {
			display: inline-block;
			width: 45px;
			text-align: center;
			margin-left: .5em;
		}
	}

	.student-rubric-settings {
		display: inline-block;

		.checkbox {
			display: block;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.activity-grading-form-group rubric-panel {
		margin-bottom: 10px;
		margin-top: 7px;
	}

	source-media-panel {
		margin-bottom: 0px;
		margin-top: 7px;
	}

	source-media-panel {
		margin-top: 10px;
	}

	// For each auxiliary (when present)
	// add extra margin that is larger than
	// when they are being displayed as the add button
	.auxiliaries-section {
		marker-set-panel,
		instructions-panel,
		rubric-panel,
		resources-panel > panel:not(.hide-paneling) {
			margin: 10px 0;
		}
	}

	// Don't add margin to whatever is the
	// first item in the auxiliaries
	.first-auxiliary {
		margin-top: 0;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance:textfield;
	}

	.video-share-popover {
		& .popover-content {
			width: 245px;

			a {
				color: @color-white;
			}
		}
	}

	.ai-markers-group {
		section {
			display: flex;
			align-items: center;
		}
	}

	.master-activity-toggle {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-top: 15px;
	}

	.ai-markers-btn-group {
		display: flex;
		align-items: center;
	}
}
