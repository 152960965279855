/** Date picker */

.datepicker {
  padding: 10px;
  max-width: none !important;

  table {
    width: auto !important;
  }

  td {
    padding: 1px;


  }
  .btn {
    padding: 3px 6px;

    .text-muted {
      color: @color-light-grey;
    }
  }
}
