@import '~go-styles/src/_/variables';

/* Page header */

// Too specific, needs to be outside
course-menu {
	display: inline-block;
}

/* Page body */
.page-body {
	display: flex;
	flex: 1;
	overflow: hidden;
	flex-direction: column;
	min-height: 0; // Fixes layout of larger children
	border-top-left-radius: @layout-card-border-radius;
	border-top-right-radius: @layout-card-border-radius;
}
