@import '~go-styles/src/_/variables';

license-management-view-route {
	display: flex;
	flex-direction: column;
	min-height: 0;
	flex: 1;
	background: white;
	height: inherit;
}
