reset-password {
	height: inherit;

	.form-action-section {
		display: flex;
		justify-content: flex-end;
	}

	password-validator {
		margin-bottom: 20px;
	}
}
